import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Crowd Moderation`}</h1>
    <ul>
      <li parentName="ul">{`⚠️ In progress (this is not a feature of pol.is but a space for working out theory behind turning `}<a parentName="li" {...{
          "href": "/Moderation",
          "title": "👹 Moderation"
        }}>{`👹 Moderation`}</a>{` over to `}<a parentName="li" {...{
          "href": "/participants",
          "title": "participants"
        }}>{`participants`}</a>{` instead of `}<a parentName="li" {...{
          "href": "/owners",
          "title": "owners"
        }}>{`owners`}</a>{`)`}</li>
    </ul>
    <hr></hr>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Since `}<a parentName="p" {...{
            "href": "/Polis",
            "title": "Polis"
          }}>{`Polis`}</a>{` `}<a parentName="p" {...{
            "href": "/conversations",
            "title": "conversations"
          }}>{`conversations`}</a>{` have an `}<a parentName="p" {...{
            "href": "/owner",
            "title": "owner"
          }}>{`owner`}</a>{` and `}<a parentName="p" {...{
            "href": "/Moderation",
            "title": "👹 Moderation"
          }}>{`👹 Moderation`}</a>{` tools to limit what `}<a parentName="p" {...{
            "href": "/comments",
            "title": "comments"
          }}>{`comments`}</a>{` are shown to `}<a parentName="p" {...{
            "href": "/participants",
            "title": "participants"
          }}>{`participants`}</a>{`, it also has an assumption of a `}<a parentName="p" {...{
            "href": "/benevolent-moderator",
            "title": "benevolent moderator"
          }}>{`benevolent moderator`}</a>{` who will act as a neutral arbiter.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`There are many `}<a parentName="p" {...{
            "href": "/benefits-to-having-a-moderator",
            "title": "benefits to having a moderator"
          }}>{`benefits to having a moderator`}</a>{` to the `}<a parentName="p" {...{
            "href": "/Polis",
            "title": "Polis"
          }}>{`Polis`}</a>{` methodology at large, as well as many `}<a parentName="p" {...{
            "href": "/risks-to-having-a-moderator",
            "title": "risks to having a moderator"
          }}>{`risks to having a moderator`}</a>{``}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`This gives way to considerations of how we could turn moderation over to the crowd without`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`This is a `}<a parentName="p" {...{
            "href": "/core-problem",
            "title": "core problem"
          }}>{`core problem`}</a>{` that `}<a parentName="p" {...{
            "href": "/Polis",
            "title": "Polis"
          }}>{`Polis`}</a>{` aims to ultimately solve`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://www.reddit.com/r/memes/comments/87qr40/downvote_buttons_are_not_disagree_buttons/"
          }}>{`https://www.reddit.com/r/memes/comments/87qr40/downvote_buttons_are_not_disagree_buttons/`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><img parentName="li" {...{
              "src": "https://preview.redd.it/60njv1qzaho01.jpg?width=960&crop=smart&auto=webp&s=4abd658db22d9c4198269209fce1f8ca1e4e1f7d",
              "alt": "r/memes - Downvote buttons are not disagree buttons"
            }}></img></li>
          <li parentName="ul"><img parentName="li" {...{
              "src": "https://pics.me.me/when-you-say-something-against-the-norm-on-reddit-downvote-32268413.png",
              "alt": "When You Say Something Against the Norm on Reddit DOWNVOTE ..."
            }}></img></li>
          <li parentName="ul"><img parentName="li" {...{
              "src": "https://i.redd.it/zffxcwcyyes21.jpg",
              "alt": "People on reddit be like : dankmemes"
            }}></img></li>
          <li parentName="ul">{`It is important to note that Reddit's model sums positive upvotes and negative upvotes: a post with +3 upvotes and -10 downvotes has a final score of -7 negative seven.`}</li>
          <li parentName="ul">{`This can be dramatic if tens of thousands of people upvote and hundreds of thousands downvote, yielding`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Brigading`}</p>
        <ul parentName="li">
          <li parentName="ul"><img parentName="li" {...{
              "src": "https://i.imgur.com/dWBJx.gif",
              "alt": "As requested, here is your massive downvote GIF. to go with the upvote gif I used earlier to whore Karma. Use it with pride!!!"
            }}></img></li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Thanks to `}<a parentName="p" {...{
            "href": "/Amy-X-Zhang",
            "title": "Amy X Zhang"
          }}>{`Amy X Zhang`}</a>{` for prompting the buildout of this page with her paper on self governing communities`}</p>
        <ul parentName="li">
          <li parentName="ul">
            <undefined parentName="li"><blockquote {...{
                "className": "twitter-tweet",
                "data-dnt": "true"
              }}><p parentName="blockquote" {...{
                  "lang": "en",
                  "dir": "ltr"
                }}>{`Ever notice how most community software encodes an admin/mod model? What about other models, like democracy? What if communities could *build for themselves* the governance that suits them?`}<br parentName="p"></br><br parentName="p"></br>{`Presenting our `}<a parentName="p" {...{
                    "href": "https://twitter.com/hashtag/uist2020?src=hash&ref_src=twsrc%5Etfw"
                  }}>{`#uist2020`}</a>{` paper on "PolicyKit: Building Governance in Online Communities"! `}<a parentName="p" {...{
                    "href": "https://t.co/xesvJ8YePn"
                  }}>{`pic.twitter.com/xesvJ8YePn`}</a></p>{`— Amy Zhang (@amyxzh) `}<a parentName="blockquote" {...{
                  "href": "https://twitter.com/amyxzh/status/1295447132971966473?ref_src=twsrc%5Etfw"
                }}>{`August 17, 2020`}</a></blockquote>{`

`}</undefined>
          </li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      